import "react-phone-input-2/lib/style.css"
import { isRecipientReady, recipient, setRecipient } from "../stores/cart"
import { useStore } from "@nanostores/react"
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { useEffect, useState } from "react";


/**
 * Let the user add a shipping address.
 * 
 * @returns 
 */
export default function Address(){

    const currentRecipient = useStore(recipient);

    const recipientReady = useStore(isRecipientReady);        

    const {t} = useTranslation();

    const mobileCountryChanged = (value, country, e, formattedValue) => {
                
        currentRecipient.dialCode = "+" +country.dialCode;
        setRecipient(currentRecipient);
    }

    // const inputValidate = (event) => {
    //     if(event.target.value){            
    //         event.target.className = event.target.className + ' border-green-300'
    //     }else{

    //     }
    // }

    const updateRecipient = (event) => {
        if(event.target.value){
            
            currentRecipient.recipient = event.target.value;
            setRecipient(currentRecipient);
        }        

    }    

    const updatePhone = (event) => {
        if(event.target.value){
            const phone = event.target.value;
            currentRecipient.phone = phone;
            // if(phone.startsWith('0')){
            //     currentRecipient.phone = phone.slice(1, phone.length);
            // }else{
                
            // }
            
            setRecipient(currentRecipient);
        }
    }    

    const updateCountry = (event) => {
        if(event.target.value){
            currentRecipient.country = event.target.value;
            setRecipient(currentRecipient);
        }
    }

    const updateProvince = (event) => {
        if(event.target.value){
            currentRecipient.province = event.target.value;
            setRecipient(currentRecipient);
        }
    }

    const updateDistrict = (event) => {
        if(event.target.value){
            currentRecipient.district = event.target.value;
            setRecipient(currentRecipient);
        }
    }

    const updateSubdistrict = (event) => {
        if(event.target.value){
            currentRecipient.subdistrict = event.target.value;
            setRecipient(currentRecipient);
        }
    }

    const updateAddress = (event) => {
        currentRecipient.address = event.target.value;
        setRecipient(currentRecipient);        
    }

    const updatePostalCode = (event) => {
        currentRecipient.postal_code = event.target.value;
        setRecipient(currentRecipient);        
    }

    useEffect(() => {
        currentRecipient.dialCode = '+66';
        setRecipient(currentRecipient);
    }, []);

    


    return (
        <>            
            <div className="border rounded-lg bg-slate-50 shadow-lg mt-5">
                <form className="p-3" autoComplete="off">
                {!recipientReady && (
                    <h3 className="w-fit mx-auto mb-5 text-md font-medium text-gray-700 py-2 px-3 bg-yellow-300 rounded-2xl">{t('cart.address.title')}</h3>               
                )}
                {recipientReady && (
                    <h3 className="w-fit mx-auto mb-5 text-md font-medium text-gray-700 py-2 px-3 bg-green-300 rounded-2xl">{t('cart.address.ready')}</h3>               
                )}
                    
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                        <div className="relative z-0 mb-6 w-full group">
                            <input type="text" name="name" id="name" className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`} placeholder=" " required onChange={updateRecipient} defaultValue={currentRecipient.recipient}/>

                            <label htmlFor="name" className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                {t('cart.address.name')}
                            </label>
                        </div>

                        
                        <div className="mb-6 w-full">
                            <label htmlFor="first_name" className="block mb-2 text-xs text-gray-500 dark:text-gray-300">{t('cart.address.phone')}</label>                        
                            <PhoneInput country={'th'} placeholder={`090 888 5555`} onChange={mobileCountryChanged} disableCountryCode={true} onlyCountries={['th', 'sg', 'au']} name="phone" id="phone" inputStyle={{ width: "100%" }} onBlur={updatePhone} value={currentRecipient.phone}/>                                                                                
                        </div>
                    </div>

                    <div className="relative z-0 mb-6 w-full">                        
                        <select id="countries" className="bg-gray-100 border-0 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={updateCountry} defaultValue={currentRecipient.country}>
                            <option value="thailand">{t('words.thailand')}</option>
                            <option value="singapore">{t('words.singapore')}</option>                        
                        </select>
                    </div>


                    <div className="grid grid-cols-2 md:grid-cols-4 gap-5">


                        <div className="relative z-0 mb-6 w-full group">
                            <input type="text" name="province" id="province" className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`} placeholder=" " required onChange={updateProvince} defaultValue={currentRecipient.province}/>
                            <label htmlFor="province" className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                {t('cart.address.province')}
                            </label>
                        </div>
                        
                        <div className="relative z-0 mb-6 w-full group">
                            <input type="text" name="district" id="district" className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`} placeholder=" " required onChange={updateDistrict} defaultValue={currentRecipient.district} />
                            <label htmlFor="district" className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                {t('cart.address.district')}
                            </label>
                        </div>

                        <div className="relative z-0 mb-6 w-full group">
                            <input type="text" name="subdistrict" id="subdistrict" className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`} placeholder=" " required onChange={updateSubdistrict} defaultValue={currentRecipient.subdistrict} />
                            <label htmlFor="subdistrict" className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                {t('cart.address.subdistrict')}
                            </label>
                        </div>

                        <div className="relative z-0 mb-6 w-full group">
                            <input type="number" name="postal_code" id="postal_code" className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`} placeholder=" " required onChange={updatePostalCode} defaultValue={currentRecipient.postal_code} />
                            <label htmlFor="postal_code" className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                {t('cart.address.postal_code')}
                            </label>
                        </div>
                    </div>

                    <div className="relative z-0 mb-6 w-full group">
                        <input type="text" name="address" id="address" className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`} placeholder=" " required onChange={updateAddress} defaultValue={currentRecipient.address} />
                        <label htmlFor="address" className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            {t('cart.address.address')}
                        </label>
                    </div>

                </form>
            </div>            
            
        </>
    )
}