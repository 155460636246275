import { Link } from "react-router-dom"
import { footerNav, setFooterNav } from "../stores/main"
import { useStore } from "@nanostores/react"

export default function Footer(){

    const currentFooterNav = useStore(footerNav);



    return (        
        <footer
            className={`fixed w-full bottom-1 p-1 flex items-center justify-between bg-green-100 shadow-3xl text-gray-400 rounded-2xl cursor-pointer`}>

            
            <Link to="/" onClick={() => setFooterNav('home')}>
            <div className={`flex flex-col p-2 px-6 rounded-2xl items-center transition ease-in duration-200 hover:text-blue-400 ${currentFooterNav === 'home' && 'bg-green-300'}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M6 19h12V9.157l-6-5.454-6 5.454V19zm13 2H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9a1 1 0 0 1-1 1zM7.5 13h2a2.5 2.5 0 1 0 5 0h2a4.5 4.5 0 1 1-9 0z"/></svg>
            </div>
            </Link>
            
            <div id="chat-toggle" className="flex flex-col items-center transition ease-in duration-200 hover:text-blue-400">            
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6-.097 1.016-.417 2.13-.771 2.966-.079.186.074.394.273.362 2.256-.37 3.597-.938 4.18-1.234A9.06 9.06 0 0 0 8 15Zm0-9.007c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z"/>
            </svg>

            </div>            
            
            <Link to={`/cart`} onClick={() => setFooterNav('cart')}>
            <div className={`flex flex-col p-2 px-6 rounded-2xl items-center transition ease-in duration-200 hover:text-blue-400 ${currentFooterNav === 'cart' && 'bg-green-300'}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M4 16V4H2V2h3a1 1 0 0 1 1 1v12h12.438l2-8H8V5h13.72a1 1 0 0 1 .97 1.243l-2.5 10a1 1 0 0 1-.97.757H5a1 1 0 0 1-1-1zm2 7a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm12 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"/></svg>
            </div>
            </Link>
            <Link to={`/profile`} onClick={() => setFooterNav('profile')}>
            <div className={`flex flex-col p-2 px-6 rounded-2xl items-center transition ease-in duration-200 hover:text-blue-400 ${currentFooterNav === 'profile' && 'bg-green-300'}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"/></svg>
            </div>
            </Link>
        </footer>
        
    )
}