// get a list of models under a brand
const API_DOMAIN = 'https://app.coverme.top/api/v1/';

const getBrandModels = async (brand, page) => {
    return await fetch(`${API_DOMAIN}products/brand/models?brand=${brand}&page=${page}&support=3d`)
    .then(res => res.json());
}

// get a brand model's information
const getBrandModel = async (modelId) => {
    return await fetch(`${API_DOMAIN}products/brand/model/${modelId}`).then(res => res.json());
}

const searchModel = async (terms, brand = '', support = '3d') => {
    const params = new URLSearchParams({
        terms: terms,
        brand: brand,
        support: support
    });

    return await fetch(`${API_DOMAIN}products/models/search?${params}`).then(res => res.json());
}

const getOrder = async (orderId, mobile, orderNumber) => {
    const params = new URLSearchParams({
        mobile: mobile,
        order_number: orderNumber
    });

    return await fetch(`${API_DOMAIN}order/${orderId}?${params}`).then(res => res.json());
}

export{
    API_DOMAIN,
    getBrandModels,
    getBrandModel,
    searchModel,
    getOrder
}