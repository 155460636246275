import { useTranslation } from "react-i18next"; 

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getOrder } from "../../api/order";
import { useNavigate } from "react-router-dom";

export default function ViewOrder(props){
    
    const {t} = useTranslation();
    
    const [ searchParams, setSearchParams ] = useSearchParams();

    const { orderId } = useParams();    

    const navigate = useNavigate();

    const [currentOrder, setCurrentOrder] = useState({});
    
    useEffect(() => {
        async function loadOrder(id, mobile, orderNumber){
            const json = await getOrder(id, mobile, orderNumber);            
            if(json.data){
                setCurrentOrder(json.data);                
            }else{
                /// has error
            }
            
        }

        const mobile = searchParams.get('mobile');
        const orderNumber = searchParams.get('order_number');
        if(!mobile || !orderNumber){            
            navigate('/profile');
        }        
        loadOrder(orderId, mobile, orderNumber);
    }, []);
        

    return (
        <>
            <Header/>
            {(currentOrder && currentOrder.id > 0) && (
                <div className="w-full mt-3">                               
                    <div className="mx-5 border p-3 shadow-2xl rounded-2xl mb-5">
                        <h2 className="text-2xl font-bold w-fit mx-auto">{t('order.title')}</h2>
                        <h3 className="text-lg font-medium w-fit mx-auto mb-3">{t('order.summary.title', {orderId: currentOrder.id})}</h3>
                        <p className="text-md font-medium w-fit mx-auto mb-2">{t('order.subtitle.open')}</p>
                        <p className="text-md text-gray-500 mb-2 text-center">{t('order.summary.content')}</p>
                    </div>
                    <div className="mx-5 border p-3 shadow-2xl rounded-2xl mb-5">
                        <h3 className="text-xl font-bold w-fit mx-auto mb-2">{t('order.shipping.title')}</h3>
                        <h4 className="text-sm text-gray-500 font-medium mb-3 text-center">{t('order.shipping.subtitle')}</h4>                        
                        <address className="text-center">
                        <h4>{currentOrder.customer.recipient_name}</h4>
                        <a href={`tel: ${currentOrder.mobile}`}>{currentOrder.mobile}</a>
                        <p>
                            {currentOrder.address.address}
                        </p>
                        <p>
                            {`${currentOrder.address.subdistrict ?? ''} ${currentOrder.address.district} ${currentOrder.address.province} ${currentOrder.address.country}`}
                        </p>
                        <p className="my-2 text-md font-medium">{t('cart.summary.shipping_fee', {amount: currentOrder.shipping_fee ? `${currentOrder.shipping_fee} ฿` : t('words.free')})}</p>
                        </address>                    
                        
                    </div>

                    <div className="mx-5 border p-3 shadow-2xl rounded-2xl mb-5">
                        <h3 className="text-xl font-bold w-fit mx-auto mb-2">{t('order.payment.title')}</h3>
                        <div className="mb-2 flex justify-center">
                            <div className="flex text-lg font-medium ">{t('order.payment.content', { amount: currentOrder.amount, currency: '฿'})}</div>
                            <div className="flex text-md font-light text-center">({t('cart.summary.shipping_fee', {amount: currentOrder.shipping_fee ? `${currentOrder.shipping_fee} ฿` : t('words.free')})})</div>
                        </div>
                        
                        <div>
                            {currentOrder.payment_type === 'cod' && (
                                <>
                                <p className="text-md font-medium text-center">{t('order.payment.cod.content')}</p>
                                <p className="text-sm text-gray-500 text-center">{t('order.payment.cod.condition')}</p>
                                </>
                            )}
                            {currentOrder.payment_type === 'paypal' && (
                                <p className="text-md md:text-lg font-medium">{t('order.payment.paypal.content')}</p>
                            )}
                        </div>
                    </div>

                    <div className="mx-5 border p-3 shadow-2xl rounded-2xl mb-28">
                        <h3 className="text-xl font-bold w-fit mx-auto mb-3">{t('order.items.title')}</h3>
                        <div className="grid gap-3 grid-cols-2 md:grid-cols-4">
                            {currentOrder.items.map(item => (
                                <div key={item.id} className="shadow-md bg-white rounded-lg border border-gray-200 shadow-m">
                                    <figure>
                                        <img className="rounded-t-lg" src={item.finial_image} alt=""/>
                                    </figure>
                                    <div className="p-5">
                                        
                                        <h5 className="mb-2 text-md w-fit mx-auto font-bold tracking-tight text-gray-900 dark:text-white">{item.name}</h5>
                                        
                                        <p className="text-md w-fit mx-auto">{item.unit_price}฿</p>
                                    </div>
                                </div>
                            ))}                
                        </div>                        
                    </div>
                                
                </div>
            )}
            <Footer/>
        </>
    )
}