import React, { useEffect, useState } from "react";
import { addCartItem } from "../stores/cart";
import vanillaToast from "vanilla-toast";
import { getBrandModel } from "../api/order";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

import { useNavigate } from "react-router-dom";

import { API_DOMAIN } from "../api/order";

import ReactGA from "react-ga4";
import { setFooterNav } from "../stores/main";

// do the upload job
export default function Upload(props) {
  const [showUploadButton, setShowUploadButton] = useState(false);

  const [showPreview, setShowPreview] = useState(false);

  const [showProgressBar, setShowProgressBar] = useState(false);

  const [pbarWidth, setPbarWidth] = useState(10);

  const [model, setModel] = useState([]);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { modelId } = useParams();

  const generatePreview = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const allowed = ["image/jpeg", "image/git", "image/png", "image/webp"];
      if (!allowed.includes(file.type)) {
        vanillaToast.error(t("upload.messages.error_file_type"));
        event.target.value = "";
        return;
      }

      if (file.size > 1024 * 1024 * 10) {
        vanillaToast.error(t("upload.messages.error_file_size_is_too_big"));
        event.target.value = "";
        return;
      }

      const elPreivew = document.getElementById("preview");
      const elImagePreview = document.getElementById("img-preview");
      elImagePreview.src = URL.createObjectURL(file);
      elPreivew.classList.remove("hidden");

      setShowUploadButton(true);
    }
  };

  /**
   * uplaod new image to the remote api
   * after success will call the add2cart function add item to the shopping cart.
   */
  const upload = () => {
    const el = document.getElementById("image");
    if (el && el.files && el.files.length > 0) {
      const file = el.files[0];
      const formData = new FormData();
      formData.append("image", file);
      formData.append("payload", JSON.stringify(model));

      // show pbar
      setShowProgressBar(true);

      const elPbar = document.getElementById("pbar");
      // send request
      const request = new XMLHttpRequest();
      request.open("POST", `${API_DOMAIN}upload`);
      // update progress bar
      request.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const percent = Math.ceil((event.loaded / event.total) * 100);
          elPbar.style.width = percent + "%";
        }
      });

      request.upload.addEventListener("load", function () {
        elPbar.style.width = "100%";
      });

      request.addEventListener("load", (event) => {
        vanillaToast.success(t("upload.messages.upload_success"));
        add2cart(event.target.response);
        setShowUploadButton(false);
      });

      request.send(formData);
    }
  };

  // add item 2 cart after 
  const add2cart = (resp) => {
    const json = JSON.parse(resp);
    const { id, url, expires_at } = json;
    let item = model.items.filter((i) => i.category === "3d");
    item = item[0] ?? { id: 0, price: 0 };
    addCartItem({
      id: id,
      tfid: id,
      name: `${model.brand} ${model.model}`,
      url: url,
      expires_at: expires_at,
      item_id: item.id,
      quantity: 1,
      comments: "",
      price: item.promote_price ?? item.sale_price,
    });

    try{
      ReactGA.event('conversion', {
        'send_to': 'AW-878343458/ztn5CLCFnIAYEKLq6aID'
      });
    }catch(e){
      console.error("Can not send ga event", e);
    }

    setFooterNav('cart');
    
    navigate(`/cart`);
  };

  useEffect(() => {
    getBrandModel(modelId).then((res) => {      
      setModel(res.data);
    });
  }, []);

  return (
    <>
      <Header />
      <div className="mt-3 mb-28">
        
          <h3 className="w-fit mx-auto text-md font-bold mb-3">
            {t("upload.title")}
          </h3>
          <figure className="mt-5 w-1/3 mx-auto">
            <img src={model.url} alt="" />
            <figcaption className="w-fit mx-auto text-md font-bold mb-3">
              {`${model.brand} ${model.model}`}
            </figcaption>
          </figure>        

        <div className="w-full">
          <div
            className="mx-auto w-fit border shadow-lg drop-shadow-lg rounded-lg hidden"
            id="preview"
          >
            <img id="img-preview" src="" alt="" />

            <div
              className={`w-full mt-2 bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 ${
                !showProgressBar && "hidden"
              }`}
            >
              <div id="pbar" className="bg-blue-600 h-2.5 rounded-full"></div>
            </div>
          </div>

          <div className="my-3 mx-10 flex justify-center">
            <input
              className="w-fit"
              type="file"
              name="image"
              id="image"
              accept="image/*"
              onChange={generatePreview}
            />
            <div className="w-full hidden bg-gray-200 rounded-full h-2.5">
              <div
                id="pbar"
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: pbarWidth }}
              ></div>
            </div>
          </div>

          {showUploadButton && (
            <div className="mx-5 flex justify-center">
              <button
                className="w-2/3 flex justify-center text-lg font-bold p-3 bg-green-400 hover:bg-green-500 rounded-xl"
                onClick={upload}
              >
                {t("upload.btn_upload")}
              </button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
