import { useTranslation } from "react-i18next"
import { getCartTotal, getShippingFee } from "../stores/cart";
import { useStore } from "@nanostores/react";


export default function CartSummary(props){

    const cartTotal = useStore(getCartTotal);

    const shippingFee = useStore(getShippingFee);

    const {t} = useTranslation();
    
    return (
        <>
            <div className="border py-3 text-center rounded-lg bg-slate-50 shadow-lg">
                <ul>
                    <li>{t('cart.summary.total', {amount: cartTotal})} ฿</li>
                    <li>{t('cart.summary.shipping_fee', {amount: shippingFee ? `${shippingFee} ฿` : t('words.free')})}</li>
                </ul>
            </div>
        </>
    )
}