import { payment, setPayment } from "../stores/cart"
import { useStore } from "@nanostores/react"
import { useTranslation } from "react-i18next";

export default function Payment(){

    const currentPayment = useStore(payment);    

    const { t } = useTranslation();

    const changePaymentMethod = (method) => {
        setPayment(method);
    }

    return (
        <>
            <div className="grid grid-cols-2 gap-6">                
                <div className={`border m-3 p-3 shadow-lg rounded-lg bg-slate-50 ${currentPayment === 'cod' && 'bg-green-500'}`} onClick={() => changePaymentMethod('cod')}>
                    <div className="flex justify-center justify-items-center justify-self-center">
                        <img className="flex w-12 h-12" src="/images/icons/cod.png" alt="" />
                        <span className="flex">{t('words.cod')}</span>
                    </div>
                </div>

                {/* <div className={`border m-3 p-3 shadow-lg rounded-lg bg-slate-50 ${currentPayment === 'paypal' && 'bg-green-500'}`} onClick={() => changePaymentMethod('paypal')}>
                    <div className="flex justify-center justify-items-center justify-self-center">
                        <img className="flex w-12 h-12" src="/images/icons/paypal.png" alt="" />
                        <span className="flex">PayPal</span>
                    </div>
                </div> */}
            </div>
        </>
    )
}