import React, { useEffect, useState } from 'react';
import {useTranslation} from "react-i18next"


const LanguageSelector = (props) => {

    const languages = [
        {
            name: 'English',
            value: 'en'
        },{
            name: 'ภาษาไทย',
            value: 'th'
        },
        {
            name: '中文',
            value: 'zh'
        }
    ];

    const {i18n}  = useTranslation();

    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);    

    const changeLanguage = (event) =>{        
        let language = event.target.value ?? 'en';
        if(!['en-US', 'th-TH', 'en', 'th', 'zh', 'zh-CN', 'zh-TW'].includes(language)){
            language = 'en';
        }
        
        i18n.changeLanguage(language);
        
        setCurrentLanguage(language);
    }    

    useEffect(() => {
        
        if(currentLanguage.length === 5){
            setCurrentLanguage(currentLanguage.substring(0, 2));
        }
        // clean
        const exists = document.getElementById('twak-chat-widget');
        if(exists){
            exists.remove();
        }        
        // dispatch src
        let src = "https://embed.tawk.to/6346217254f06e12d899ad0c/1gg2075ev";
        if(currentLanguage === 'th'){
            src = "https://embed.tawk.to/6346217254f06e12d899ad0c/1gf4v5ab3";
        }

        var s1=document.createElement("script");
        s1.id = 'twak-chat-widget';
        s1.async=true;
        s1.src=src;
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        document.body.append(s1);

    }, []);


    return (   
        <>     
            <select id="ui-language" value={currentLanguage} name="ui-language" className="border-0 text-sm rounded-md" onChange={changeLanguage}>        
            {languages.map((language) => 
                <option key={language.value} value={language.value}>{language.name}</option>                    
            )}
            </select>
        </>
    )
}

export default LanguageSelector;