import {atom} from "nanostores"
import { persistentAtom } from "@nanostores/persistent";
import { KEY_CURRENT_BRAND, KEY_CURRENT_MODEL, KEY_FOOTER_NAV } from "../libs/constants";

const initilizeValues = [
    {
        "id": 1,
        "name": "Apple",
        "logo": "/images/brands/apple.png"
    },
    {
        "id": 2,
        "name": "ASUS",
        "logo": "/images/brands/asus.png"
    },
    {
        "id": 3,
        "name": "HUAWEI",
        "logo": "/images/brands/huawei.png"
    },
    {
        "id": 4,
        "name": "Lenovo",
        "logo": "/images/brands/lenovo.png"
    },
    {
        "id": 5,
        "name": "Moto",
        "logo": "/images/brands/motorola.png"
    },
    {
        "id": 6,
        "name": "OPPO",
        "logo": "/images/brands/oppo.png"
    },
    {
        "id": 7,
        "name": "SAMSUNG",
        "logo": "/images/brands/samsung.png"
    },
    {
        "id": 8,
        "name": "VIVO",
        "logo": "/images/brands/vivo.png"
    },
    {
        "id": 9,
        "name": "XIAOMI",
        "logo": "/images/brands/xiaomi.png"
    }
]

const initilizeModels = []

// the current selected brand
const brand = persistentAtom(KEY_CURRENT_BRAND, '');
const setBrand = (b) => {
    brand.set(b);
}
// add a new brand to the store list
const addBrand = (brand) => {
    brands.set([...brands.get(), brand])
}

// the brands name list
const brands = atom(initilizeValues);


// the all models of the brand
const models = atom(initilizeModels);
const setModels = (models) => {
    models.set(models);
}


// the current select model
const model = persistentAtom(KEY_CURRENT_MODEL, '', {
    encode(value){
        return JSON.stringify(value);
    },
    decode(value){
        try{
            return JSON.parse(value);
        }catch(e){
            return value;
        }
    }
});
const setModel = (m) => {
    model.set(m)
}


const footerNav = persistentAtom(KEY_FOOTER_NAV, 'home');

const setFooterNav = (n) => {
    footerNav.set(n);
}


export {
    brands,
    addBrand,

    // creent brand
    brand,
    setBrand,

    models,
    setModels,
    //current model
    model,
    setModel,

    footerNav,
    setFooterNav
}