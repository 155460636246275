// Import the functions you need from the SDKs you need
import { 
    initializeApp
 } from "firebase/app";

import {
    getAuth, RecaptchaVerifier, signInWithPhoneNumber
} from "firebase/auth"

import {
    getAnalytics
} from "firebase/analytics"

import {
    getFirestore
    // enableMultiTabIndexedDbPersistence
} from "firebase/firestore"

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDAu0XO2xr5-tyr52Jp8blZs4DWtHZctQ0",
    authDomain: "tofu-ink.firebaseapp.com",
    projectId: "tofu-ink",
    storageBucket: "tofu-ink.appspot.com",
    messagingSenderId: "418101876911",
    appId: "1:418101876911:web:8dfee7a8d3daae64dadc5a",
    measurementId: "G-THJ6K7YDP7"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const analytics = getAnalytics(firebaseApp);

const auth = getAuth(firebaseApp);

const db = getFirestore(firebaseApp);

// enableMultiTabIndexedDbPersistence(db).catch(err => {
//     if(err.code === 'unimplemented'){
//         console.error(`The user browser is not supported.`);
//     }else{
//         console.error(`unable to enable multiple tab indexeddb persistence.`);
//     }
// });

const initRecaptchaVerifier = (elementId, fbauth, callback, expiredCallback) => {    
    window.recaptchaVerifier = new RecaptchaVerifier(elementId, {
        'size': 'invisible',
        'callback': callback,
        'expired-callback': expiredCallback
    }, fbauth);
    window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWdigetId = widgetId;
    })

    return window.recaptchaVerifier;
}

const mobileSignIn = async (fbauth, mobileNumber, verifier) => {
    return await signInWithPhoneNumber(fbauth, mobileNumber, verifier)    
}

export{
    firebaseApp,
    analytics,
    auth,
    db,

    initRecaptchaVerifier,
    mobileSignIn,
}