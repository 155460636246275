
import { confirmAlert } from "react-confirm-alert";
import { useStore } from '@nanostores/react';
import { payment, isRecipientReady, isCartReady, recipient, cartItems, setOrder, setRecipient, clearCart, saveNewOrder, getShippingFee } from "../stores/cart";
import { useTranslation } from "react-i18next";
import VanillaToast from "vanilla-toast"
import axios from "axios";
import { API_DOMAIN } from "../api/order";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import VerifyMobile from "./orders/VerifyMobile";
import { addNewOrder } from "../libs/firestore";
import { setFooterNav, setModel } from "../stores/main";

/**
 * handle the cart's checkout
 * 
 * @returns 
 */
export default function Checkout(){

    const currentPayment = useStore(payment);    

    const recipientReady = useStore(isRecipientReady);

    const cartIsReady = useStore(isCartReady);
    
    const currentRecipient = useStore(recipient);        

    const navigate = useNavigate();

    const {t, i18n} = useTranslation();
    
    const checkout = (onClose) => {                
        // create customer and shipping address.
        const payload = {
            "items": cartItems.get(),
            "customer":{
                "recipient_name": currentRecipient.recipient,
                "mobile": `${currentRecipient.dialCode} ${currentRecipient.phone}`
            },
            "address":{
                "country": currentRecipient.country,
                "province": currentRecipient.province,
                "district": currentRecipient.district,
                "subdistrict": currentRecipient.subdistrict,
                "address": currentRecipient.address,
                "postal_code": currentRecipient.postal_code
            },
            "shipping":{
                "fee": getShippingFee.value,
                "method": "standard"
            },
            "payment": {
                "method": currentPayment
            },
            "shop_id": 5,
            "locale": i18n.language ?? 'th',
            "comments": ""
        };                
        

        axios.post(`${API_DOMAIN}orders`, payload).then(res => {
            
            if(res.status === 200 || res.status === 201){
                                
                if(res.data.data?.id > 0){
                    // order place successful
                    const order = res.data.data;
                    
                    setOrder(order);                    
                    // if user logged the the new order will be logged.
                    addNewOrder(order);
                    clearCart();
                    setModel('');
                    setFooterNav('profile');

                    onClose();  
                    
                    try{
                        ReactGA.event('conversion',{
                            send_to: 'AW-878343458/cXsXCJ6wjYAYEKLq6aID',                            
                            value: order.amount,                            
                            currency: 'THB',
                            transaction_id: order.id
                        });
                    }catch(e){
                        console.log("unable to send ga event", e);
                    }

                    navigate(`/order/${order.id}?mobile=${encodeURIComponent(order.mobile)}&order_number=${encodeURIComponent(order.order_number)}`);
                    // clear cart
                }else{
                    VanillaToast.error(t('cart.checkout.messages.error_create_order'));
                    onClose();
                }
                
            }else{
                VanillaToast.error(t('cart.checkout.messages.error_create_order'));
                onClose();
            }
        }).catch(err => {
            console.log(err);
            VanillaToast.error(t('cart.checkout.messages.error_create_order'));
            onClose();
        });       
    }    

    const checkoutClicked = () => {
        if(currentPayment === 'cod'){            
            confirmAlert({
                closeOnClickOutside: false,                
                customUI: ({ onClose }) => {
                    return (
                        <VerifyMobile className="bg-green-300 py-5" mobile={`${currentRecipient.dialCode} ${currentRecipient.phone}`} title={t('order.verify_mobile.title')} content={t('order.verify_mobile.content')} onVerified={(result) => {
                            checkout(onClose);
                        }} onCancel={() => onClose()}/>
                    )
                }
            });
        }else{
            confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className="max-w-full p-6 bg-yellow-200">
                      <h1 className="text-2xl font-bold">{t('cart.checkout.confirm.title')}</h1>
                      <p className="text-lg my-3">
                        {currentPayment === 'cod' && t('cart.checkout.cod.message')}
                        {currentPayment === 'paypal' && t('cart.checkout.paypal.message')}
                      </p>
                      <button className="bg-gray-300 p-3 rounded-lg mx-5" onClick={onClose}>{t('words.no')}</button>
                      <button className="bg-green-500 p-3 rounded-lg mx-5"
                        onClick={() => {
                            if(recipientReady){
                               checkout(onClose);                    
                            }else{
                                VanillaToast.error(t('cart.checkout.messages.recipient_required'));
                            }
                        }}
                      >
                        {t('words.yes')}
                      </button>
                    </div>
                  );
                }
              });
        }        
    }

    return (
        <>      
            <div className="mx-auto mt-5 w-2/3 md:w-1/2 mb-3">
                <button onClick={() => navigate('/')} className="text-2xl font-bold bg-blue-300 hover:bg-blue-400 rounded-xl p-3 w-full my-3">{t('cart.btn_add_more')}</button>                  
                {(cartIsReady && recipientReady) && (                    
                    <button onClick={checkoutClicked} className="text-2xl font-bold bg-green-500 hover:bg-green-600 rounded-xl p-3 w-full">{t('cart.btn_checkout')}</button>                    
                )}
            </div>
            
        </>
    )
}