
import { useStore } from "@nanostores/react";

import Brand from "../components/caseprinting/Brand";
import Header from "../components/Header";
import Footer from "../components/Footer";

import { brands } from "../stores/main";

/**
 * 
 * @returns 
 */
export default function Home() {

  const allBrands = useStore(brands);

  return (
    <>            
      <Header/>
        <div className="mt-5 mb-28">
          
          <Brand brands={allBrands}/>        
        </div>
      <Footer/>
    </>
  );
}