
import { confirmAlert } from "react-confirm-alert";
import LanguageSelector from "./LanguageSelector";
import {useTranslation} from "react-i18next";
import { setFooterNav } from "../stores/main";
import { useNavigate } from "react-router-dom";

export default function Header(props){

    const {t} = useTranslation();

    const navigate = useNavigate();

    const logoClicked = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <>
                        <div className="max-w-full p-6 bg-green-200">
                            <h3 className="text-lg font-bold w-fit mx-auto">{t('headers.logo.title')}</h3>
                            <p className="text-md text-gray-700 font-light my-3">{t('headers.logo.content')}</p>
                            <p className="text-nd text-gray-700 font-light my-3">{t('headers.logo.highlight')}</p>
                            <div className="flex justify-center">
                                <button className="w-fit p-2 px-5 mx-3 bg-green-400 rounded-xl" onClick={() => onClose()}>OK</button>

                                <button className="w-fit p-2 px-5 mx-3 bg-green-400 rounded-xl" onClick={() => {
                                    setFooterNav('home');
                                    navigate();
                                    onClose();
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M6 19h12V9.157l-6-5.454-6 5.454V19zm13 2H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9a1 1 0 0 1-1 1zM7.5 13h2a2.5 2.5 0 1 0 5 0h2a4.5 4.5 0 1 1-9 0z"/></svg>
                                </button>
                            </div>
                        </div>
                    </>
                )
            }
        });
    }

    return (
        <nav className="bg-white px-2 py-2.5 dark:bg-gray-900  w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
            <div className="conta
            iner flex justify-between items-center mx-auto">
                
                <figure className="flex items-center" onClick={logoClicked}>
                    <img src="/images/logo.64x64.png" className="mr-3 h-6 sm:h-9" alt=""/>
                    <span className="self-center text-lg font-medium whitespace-nowrap dark:text-white">Tofu.Ink🐈</span>                    
                </figure>

                <div className="flex md:order-2">  
                    <LanguageSelector/>
                </div>
            </div>
        </nav>
    )
}