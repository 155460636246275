import { computed } from "nanostores";
import {persistentAtom} from "@nanostores/persistent"
import { KEY_ALL_ORDERS, KEY_CART_IS_OPEN, KEY_CART_ITEMS, KEY_CURRENT_ORDER, KEY_PAYMENT_METHOD, KEY_RECIPIENT, KEY_SAVED_RECIPIENT } from "../libs/constants";


// cart is open
const cartOpen = persistentAtom(KEY_CART_IS_OPEN, false, {
    encode(value){
        return value;
    },
    decode(value){
        return value === 'true' ? true : false;
    }
});

// set the cart open status
const setCartOpen = (s) => {
    cartOpen.set(s);      
}


// set and update cart items
const cartItems = persistentAtom(KEY_CART_ITEMS, [], {
    encode(value){
        return JSON.stringify(value);
    },
    decode(value){
        try{
            return JSON.parse(value);
        }catch(e){
            return value;
        }
    }
});

// add a new item object to the cart items collection
const addCartItem = (i) => {
    cartItems.set([...cartItems.get(), i]);    
}

// get the cart's summary
const getCartTotal = computed(cartItems, data => {
    let total = 0;
    data.forEach(i => {        
        total += i.price ;
    })
    return total;
});

const getShippingFee = computed(cartItems, data => {
    let total = 0;
    data.forEach(i => {
        total += i.price;
    });
    if(total >= 200){
        return 0;
    }else{
        return 35;
    }
})

// remove a cart item by it's id
const removeCartItem = (id) => {
    const items = cartItems.get().filter(item => item.id !== id);
    cartItems.set(items);
}

// clear the cart items
const clearCart = () => {
    cartItems.set([]);
}

// the recipient information
const recipient = persistentAtom( KEY_RECIPIENT, {country: "thailand", dialCode: '+66'}, {
    encode(value){
        if(value === ''){
            value = {
                country: "thailand",
                dialCode: '+66'
            };
        }
        return JSON.stringify(value);
    },
    decode(value){
        try{
            return JSON.parse(value);
        }catch(e){
            return {};
        }
    }
});

// all saved recipients
const recipients = persistentAtom(KEY_SAVED_RECIPIENT, [], {
    encode(value){
        if(value === ''){
            value = [];
        }
        return JSON.stringify(value);
    },
    decode(value){
        try{
            return JSON.parse(value);
        }catch(e){
            return [];
        }
    }
});

const saveNewRecipient = (r) => {
    recipients.set([...recipients.get(), r])
}

const setRecipient = (r) => {
    recipient.set(r);
}

// validate if a recipient is ready.
const isRecipientReady = computed(recipient, data => {
    if(!data.recipient || !data.phone || !data.country || !data.province || !data.district || !data.subdistrict || !data.address || !data.postal_code){
        return false;
    }
    return true;
})

const isCartReady = computed(cartItems, data => {    
    return cartItems.get().length > 0;
})

// the payment method's cart
const payment = persistentAtom(KEY_PAYMENT_METHOD, 'cod');
const setPayment = (p) => {
    payment.set(p);
}

// the current order information
const order = persistentAtom(KEY_CURRENT_ORDER, '', {
    encode(value){
        if(value === ''){
            value = {}
        }
        return JSON.stringify(value);
    },
    decode(value){
        try{
            return JSON.parse(value)
        }catch(e){
            return {};
        }
    }
});

// set a new order
const setOrder = (o) => {
    order.set(o);
}

const orders = persistentAtom(KEY_ALL_ORDERS, [], {
    encode(value){
        if(value === ''){
            value = [];
        }
        return JSON.stringify(value);
    },
    decode(value){
        try{
            return JSON.parse(value);
        }catch(e){
            return [];
        }
    }
})

// add new order to the saved list
const saveNewOrder = (o) => {
    orders.set([...orders.get(), o]);
}


export {
    cartOpen,
    setCartOpen,

    cartItems,
    addCartItem,
    removeCartItem,
    clearCart,
    getCartTotal,
    getShippingFee,

    recipient,
    setRecipient,

    recipients,
    saveNewRecipient,

    payment,
    setPayment,

    isRecipientReady,
    isCartReady,

    order,
    setOrder,

    orders,
    saveNewOrder
}