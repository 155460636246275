import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import i18n from './libs/i18n'
import { doUpdate } from './libs/upgrade';

import {
  createBrowserRouter,
  RouterProvider,
  Route
} from 'react-router-dom'


import Home from './pages/Home';
import Brand from "./components/caseprinting/Brand";
import Model from "./pages/ModelPage";
import Cart from "./pages/CartPage";
import ViewOrder from "./pages/order/ViewOrder";
import Profile from "./pages/Profile";
import ErrorPage from "./pages/ErrorPage";
import Upload from "./pages/UploadPage";


// set up ga4
import ReactGA from "react-ga4"
import TestPage from "./pages/TestPage";

import {checkForSync} from "./libs/firestore"

try{
  doUpdate();

  checkForSync();
}catch(e){

}


ReactGA.initialize([
  {
    trackingId: 'G-WQ7E2MD5RT'
  },{
    trackingId: 'AW-878343458'
  }
]);
ReactGA.send("pageview");

// end ga4


const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home/>,
    children: [    
    ]
  },  
  {
    path: 'brand',
    element: <Brand/>
  },
  {
    path: 'cart',
    element: <Cart/>
  },
  {
    path: 'profile',
    element: <Profile/>
  },
  {
    path: 'model/:brand/:page',
    element: <Model/>    
  },
  {
    path: 'upload/:modelId',
    element: <Upload/>
  },
  {
    path: 'order/:orderId',
    element: <ViewOrder/>
  },
  {
    path: '/test',
    element: <TestPage/>  
  }
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router}></RouterProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
