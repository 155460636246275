import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"

import {auth, initRecaptchaVerifier, mobileSignIn} from "../../libs/firebase";
import VanillaToastr from "vanilla-toast"
import { setUser } from "../../stores/user";



export default function VerifyMobile(props){
    
    const [otp, setOtp] = useState('');

    const [confirmationResult, setConfirmationResult] = useState(null);

    const [showBtnRequest, setShowBtnRequest] = useState(true);

    const [showBtnVerify, setShowBtnVerify] = useState(false); 

    const [inputEnable, setInputEnable] = useState(false);

    const {t, i18n} = useTranslation();

    const phoneNumber = props.mobile;
    
    const sendOtp = () => {
        auth.languageCode = i18n.language;
        const rv = initRecaptchaVerifier('btn-request-otp', auth);
        const mobileNo = phoneNumber.replace(/\s+/g, '');
        
        mobileSignIn(auth, mobileNo, rv).then((result) => {
            setConfirmationResult(result);
            VanillaToastr.success(t('order.verify_mobile.messages.otp_sent'));
            setShowBtnRequest(false);

            setShowBtnVerify(true);

            setInputEnable(true);

            setTimeout(() => {
                setShowBtnRequest(true);
            }, 60 * 1000);
        }).catch(err => {
            props.onCancel();
            VanillaToastr.error(t('order.verify_mobile.messages.error_send_otp'));
            console.log(err);
        });;
    }

    const verifyOtp = (event) => {        
        setShowBtnVerify(false);
        setInputEnable(false)
        confirmationResult.confirm(otp).then((result) => {
            
            const user = result.user;            
            setUser(user);                        
            props.onVerified(result);
        }).catch(err => {
            console.log(err);
            setShowBtnVerify(true);
            setInputEnable(true);
            VanillaToastr.error(t('order.verify_mobile.messages.otp_verify_fail'));
        });
    }

    useEffect(() => {
        
    }, []);

    return (
        <>
            <div className={`${props.className}`}>
                <h3 className="text-center text-lg font-medium my-2">{props.title}</h3>
                <h4 className="text-center text-md font-medium my-2">{props.mobile}</h4>
                <p className="text-center my-2 font-light px-5">{props.content}</p>
                <div className="text-center my-3">
                    <input type="text" onChange={(event) => setOtp(event.target.value)} className="rounded-xl border border-gray-400 focus:border-gray-500 disabled:bg-gray-200" id="verify_mobile_otp_code" name="verify_mobile_otp_code" disabled={!inputEnable}/>                    
                </div>
                <div className="flex justify-center my-3">
                
                    <button onClick={sendOtp} type="button" id="btn-request-otp" className="bg-yellow-400 hover:bg-yellow-500 disabled:bg-gray-400 py-2 px-5 rounded-xl mx-3" disabled={!showBtnRequest}>{t('order.verify_mobile.btn_request_otp')}</button>                
                    
                    <button onClick={verifyOtp} type="button" className="bg-green-400 hover:bg-green-500 disabled:bg-gray-400 py-2 px-5 rounded-xl mx-3" disabled={!showBtnVerify}>
                        {t('order.verify_mobile.btn_verify')}
                    </button>

                    
                    <button type="button" className="bg-gray-300 hover:bg-gray-400 py-2 px-5 rounded-xl mx-3" onClick={() => props.onCancel()}>{t('words.cancel')}</button>
                    
                </div>
            </div>
        </>       
    )
}