
// indicate if the shopping cart is open
const KEY_CART_IS_OPEN = 'cart-is-open';

// cureent selected brand name, string
const KEY_CURRENT_BRAND = 'current-brand';

// current selected phone model, object
const KEY_CURRENT_MODEL = 'current-model';

// current cart items
const KEY_CART_ITEMS = 'cart-items';

// current recipient information
const KEY_RECIPIENT = 'recipient';

// all saved recipients
const KEY_SAVED_RECIPIENT = 'saved-recipient';

// current selected payment method
const KEY_PAYMENT_METHOD = 'payment-method';

// current order's information
const KEY_CURRENT_ORDER = 'current-order';

// all orders saved, object array 
const KEY_ALL_ORDERS = 'all-orders';

// app's current version
const KEY_APP_VERSION = 'tofu-app-version';

// current selected nav menu
const KEY_FOOTER_NAV = 'tofu_current_nav';

const KEY_CURRENT_USER = 'tofu_current_user';


export {
    KEY_CART_IS_OPEN,
    KEY_CURRENT_BRAND,
    KEY_CURRENT_MODEL,
    KEY_CART_ITEMS,
    KEY_RECIPIENT,
    KEY_SAVED_RECIPIENT,
    KEY_PAYMENT_METHOD,
    KEY_CURRENT_ORDER,
    KEY_ALL_ORDERS,
    KEY_APP_VERSION,
    KEY_FOOTER_NAV,
    KEY_CURRENT_USER
}