import React, { useEffect, useState } from "react";
import { setModel, setBrand } from "../stores/main";
import { getBrandModels, searchModel } from "../api/order";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Model() {
  const [pageData, setPageData] = useState([]);

  const [rawData, setRawData] = useState([]);

  const [totalPage, setTotalPage] = useState(0);

  const { brand, page = 1 } = useParams();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const loadPageData = async (b, p) => {
    console.log(b, p);
    const json = await getBrandModels(b, p);
    console.log(json);
    setRawData(json.data);
    setPageData(json.data);
    setTotalPage(json.meta.last_page);
  };

  // search a model
  const search = (event) => {
    console.log(event.target.value);
    if(event.target.value && event.target.value.length > 1){        
        const value = event.target.value.toLowerCase();
        searchModel(value, brand).then(res => {
            if(res.data){
                setPageData(res.data);
            }            
        });
    }else{
      console.log(rawData);
        setPageData(rawData);
    }
    
  };

  // select a model
  const modelClicked = (model) => {
    setModel(model);
  };

  // pagination, next page
  const nextPage = () => {
    if (page < totalPage) {
      const p = parseInt(page) + 1;
      navigate(`/model/${brand}/${p}`);
    }
  };

  // pagination, previous page
  const previousPage = () => {
    if (page > 1) {
      const p = parseInt(page) - 1;
      navigate(`/model/${brand}/${p}`);
    }
  };

  useEffect(() => {
    console.log("mounted");
    loadPageData(brand, page);
  }, [brand, page]);

  return (
    <>
      <Header />
      <div className="mt-3 mb-28 overflow-y-auto">
        <div className="block">
          <h3 className="text-xl font-bold mx-auto w-fit">
            {t("model.title", { brand: brand })}
          </h3>
          <p className="text-md mx-auto w-fit">{t("model.subtitle")}</p>
        </div>

        <div className="flex flex-row justify-center mt-3">
          <input
            type="text"
            className="flex w-2/3 border rounded-lg mr-3"
            id="model-search"
            onChange={search}
            placeholder={t("words.search")}
            autoFocus={true}
          />
        </div>
        <div className="flex flex-row w-full flex-wrap justify-center">
          {pageData &&
            pageData.map((model) => (            
                <div
                    className="bg-gray-100 shadow-xl drop-shadow-xl rounded-xl w-1/4 md:w-1/6 lg:w-1/12 p-3 mx-2 my-2 border-0 border-gray-900 hover:bg-gray-200 cursor-pointer"                    
                    key={model.id}
                    onClick={() => {
                    modelClicked(model);
                    }}
                >
                <Link to={`/upload/${model.id}`}>
                    <figure>
                    <img
                        className="mx-auto"
                        width="64"
                        height="64"
                        src={model.url}
                        alt={model.model}
                    />
                    <figcaption className="w-fit mx-auto text-md font-bold">
                        {model.model}
                    </figcaption>
                    </figure>
                </Link>
                </div>              
            ))}
        </div>
        <div className="pagination">
          <div className="flex flex-col items-center">
            <span className="text-sm text-gray-700 dark:text-gray-400">
              <span className="font-semibold text-gray-900 dark:text-white">
                {page}
              </span>{" "}
              /{" "}
              <span className="font-semibold text-gray-900 dark:text-white">
                {totalPage}
              </span>
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                className="py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={previousPage}
              >
                {t("words.previous")}
              </button>
              <button
                className="py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-r border-0 border-l border-gray-700 hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={nextPage}
              >
                {t("words.next")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
