import Footer from "../components/Footer";
import Header from "../components/Header";
import OrderList from "../components/orders/OrderList";


export default function Profile(){
    return (
        <>
            <Header/>
            <div className="mt-3 mb-28">            
                <OrderList/>
            </div>
                
            <Footer/>
        </>
    )
}