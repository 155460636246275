import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getOrders } from "../../libs/firestore";

export default function OrderList(){
    

    const [orders, setOrders] = useState([]);

    
    const {t} = useTranslation();    

    useEffect(() => {
        async function loadOrders() {
            const data  =await getOrders();
            setOrders(data);
        }

        loadOrders();
    }, []);

    return (
        <>
            <div className="border bg-slate-100 rounded-2xl p-3 mt-3 mx-3">
            {(orders && orders.length > 0) && (
                <>
                <h3 className="font-medium">{t('profile.order.list.title')}</h3>
                <ul>
                    {orders.map(order => (                        
                        <li key={order.order_id} className="text-blue-400 my-2 p-2">
                            <Link to={`/order/${order.order_id}?mobile=${encodeURIComponent(order.mobile)}&order_number=${encodeURIComponent(order.order_number)}`}># {order.order_id} - {order.amount}</Link>
                        </li>                        
                    ))}
                    
                </ul>
                </>
            )}           
            {(orders && orders.length === 0) && (
                <p className="text-lg font-medium">{t('profile.order.list.p_no_orders')}</p>
            )}     
            </div>
        </>
    )
}