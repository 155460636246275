import Header from "../components/Header"
import Footer from "../components/Footer"
import VerifyMobile from "../components/orders/VerifyMobile"
import { useTranslation } from "react-i18next"

export default function TestPage(){
    const {t} = useTranslation();

    const callback = () => {
        console.log('execute callback');
    }
    return (
        <>            
        <Header/>
          <div className="mt-5">
          <VerifyMobile mobile="+66 901231234" title={t('order.verify_mobile.title')} content={t('order.verify_mobile.content')} callback={callback}/>                      
          </div>
        <Footer/>
      </>
    )
}