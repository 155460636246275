import React from "react"
import { brand, model, setBrand } from "../../stores/main"
import { confirmAlert } from "react-confirm-alert";

import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useStore } from "@nanostores/react";
import { useNavigate } from "react-router-dom";
import SWU from "../SWU"
import Countdown from "react-countdown";

// show the brand list
export default function Brand (props){            

    const {t} = useTranslation();

    const currentBrand = useStore(brand);

    const currentModel = useStore(model);
  
    const navigate = useNavigate();

    const brandClicked = (brand) => {                
        setBrand(brand);                               
        navigate(`/model/${brand}/1`);
    }
    
    useEffect(() => {
        if(currentBrand && currentModel){
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <>
                  <div className="max-w-full p-6 bg-green-200">
                      <h1 className="text-2xl font-bold">{t('brand.messages.use_previous_model.title')}</h1>
                      <p className="text-lg">
                        {t('brand.messages.use_previous_model.content', {brand: currentBrand, model: currentModel.model})}                    
                      </p>
                      <button className="bg-gray-300 p-3 rounded-lg mx-5" onClick={() => {
                        setBrand('');
                        onClose()
                      }}>{t('words.no')}</button>
                      <button className="bg-green-500 p-3 rounded-lg mx-5"
                        onClick={() => {
                            navigate(`/upload/${currentModel.id}`);
                            onClose();
                        }}
                      >
                        {t('words.yes')}
                      </button>
                    </div>
                </>
              )
            }
          });
        }
      },[])

    return (        
        <>                      
          <h3 className="text-lg font-bold w-fit mx-auto mt-3">{t('brand.title')}</h3>
          
          <h4 className="text-sm font-medium text-gray-600 mx-auto text-center mt-2 mark-yellow-400 rounded-md p-2">{t('brand.subtitle')}</h4>                        
          
          <div className="brands flex flex-row flex-wrap justify-center">        
          {props.brands.map((brand) => (                    
              <div key={brand.name} className="flex min-w-max mx-2 my-3 border-2 rounded-xl shadow-lg p-3 cursor-pointer bg-gray-50 hover:bg-gray-200" onClick={() => brandClicked(brand.name)}>
                  <figure>
                      <img className="w-fit mx-auto" src={brand.logo} alt=""/>
                      <figcaption className="w-fit mx-auto text-lg font-bold">{brand.name}</figcaption>
                  </figure>
              </div>                    
          ))}
          </div>               
          <div className="mx-auto w-fit">
          <SWU/>
          </div>
        </>
    )    
}