import React from "react";
import { cartItems, removeCartItem } from "../stores/cart";
import { useStore } from "@nanostores/react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useTranslation } from "react-i18next";
import Checkout from "../components/Checkout";
import CartSummary from "../components/CartSummary";
import Address from "../components/Address";
import Payment from "../components/Payment";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Cart(props) {
  const allCartItems = useStore(cartItems);

  const { t } = useTranslation();

  // delete a cart item by item's id
  const deleteCartItem = (itemId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <>
            <div className="max-w-full p-6 bg-yellow-200">
              <h1 className="text-lg font-bold">
                {t("cart.item.delete.title")}
              </h1>
              <p className="text-md font-medium my-3">
                {t("cart.item.delete.content")}
              </p>
              <button
                className="bg-gray-300 p-3 rounded-lg mx-5"
                onClick={onClose}
              >
                {t("words.no")}
              </button>
              <button
                className="bg-green-500 p-3 rounded-lg mx-5"
                onClick={() => {
                  removeCartItem(itemId);
                  onClose();
                }}
              >
                {t("words.yes")}
              </button>
            </div>
          </>
        );
      },
    });
  };

  return (
    <>
      <Header />
      {allCartItems.length === 0 && (
        <div className="w-full mt-5 mb-20">
          <figure className="w-fit mx-auto rounded-2xl shadow-2xl drop-shadow-lg">
            <img src="/images/bg/snap_the_moment.png" alt="empty cart" />
            <figcaption className="text-lg font-medium mx-auto w-fit p-3">
              {t("cart.p.no_cart_items")}
            </figcaption>
          </figure>
        </div>
      )}

      {allCartItems.length > 0 && (
        <div className="block mx-3 rounded-xl shadow-2xl drop-shadow-xl overflow-y-scroll mt-10 mb-28">
          <div className="grid gap-3 grid-cols-2 md:grid-cols-4 mx-5">
            {allCartItems.map((item) => (
              <div
                key={item.id}
                className="shadow-lg bg-white rounded-lg border border-gray-200 shadow-m"
              >
                <button
                  type="button"
                  className="inline-flex float-right m-2 items-center align-bottom py-1 px-2 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300"
                  onClick={(e) => deleteCartItem(item.id)}
                >
                  {t("cart.btn_delete")}
                </button>
                <figure>
                  <img className="rounded-t-lg" src={item.url} alt="" />
                </figure>
                <div className="p-5">
                  <h5 className="mb-2 text-md w-fit mx-auto font-bold tracking-tight text-gray-900 dark:text-white">
                    {item.name}
                  </h5>
                </div>
              </div>
            ))}
          </div>
          
          <div className="mx-3 mt-3">
            <Address />
          </div>
          <div className="mx-3 mt-3">
            <CartSummary />
          </div>
          <div className="mx-3 mt-3">
            <Payment />
          </div>

          <div className="mx-3 mt-3">
            <Checkout />
          </div>
        </div>
      )}

      <Footer />
    </>
  );
}
