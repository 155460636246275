import { db } from "./firebase";
import { doc, addDoc, getDoc, query, where, getDocs, collection } from "firebase/firestore";

import { user } from "../stores/user";
import { orders } from "../stores/cart";

const uid = user.get().uid ?? null;

// check for local orders history and sync to firebase
const checkForSync = async () => {    
    // if user is authed and local orders record more than one.
    if(uid && orders.get().length > 0){        
        orders.get().forEach((order, index) => {                         
            addNewOrder(order);            
        });

        orders.set([]);        
    }
}

const addNewOrder = (order) => {
    if(uid){
        addDoc(collection(db, 'users_orders'), {
            user_id: uid,
            order_id: order.id,
            mobile: order.mobile,
            shop_id: order.shop_id,
            amount: order.amount,
            order_number: order.order_number                
        });
    }
}

/**
 * Get the user's all orders
 * @returns list of the orders
 */
const getOrders = async () => {
    const result = [];
    if(uid){
        const q = query(collection(db, 'users_orders'), where('user_id', '==', uid));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(doc => {
            result.push(doc.data());
        })
    }
    
    return result;
}

export{
    checkForSync,
    addNewOrder,
    getOrders
}