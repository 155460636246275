import { persistentAtom } from "@nanostores/persistent";
import { KEY_CURRENT_USER } from "../libs/constants";

// the current logged user
const user = persistentAtom(KEY_CURRENT_USER, {}, {
    encode(value){
        return JSON.stringify(value);
    },
    decode(value){
        try{
            return JSON.parse(value);
        }catch(e){
            console.log(e);
            return [];
        }
    }
});


// set the current user
const setUser = (u) => {
    user.set(u);
}

export {
    user,
    setUser
}