import { KEY_APP_VERSION, KEY_CURRENT_BRAND, KEY_CURRENT_MODEL, KEY_CURRENT_ORDER, KEY_RECIPIENT } from "./constants";

const version = 20221020;

const pwaVersion = localStorage.getItem(KEY_APP_VERSION);

const doUpdate = () => {
    if(!pwaVersion || pwaVersion < version){
        localStorage.removeItem(KEY_CURRENT_ORDER);
        localStorage.removeItem(KEY_CURRENT_BRAND);
        localStorage.removeItem(KEY_CURRENT_MODEL);
        localStorage.removeItem(KEY_RECIPIENT);

        localStorage.setItem(KEY_APP_VERSION,version);
    }
}



export {
    doUpdate
}